export const BODY_FONT = "Roboto Serif";
export const HEADER_FONT = "Roboto";
export const MONO_FONT = "Roboto Mono";

const fonts = {
  HEADER_FONT,
  BODY_FONT,
  MONO_FONT,
};

export default fonts;
